export const endpoints = {
    // GEOSERVER: 'http://192-155-94-240.ip.linodeusercontent.com:8080/geoserver/cite',
    GEOSERVER: '/geoserver/cite', // prod
    // GEOSERVER: 'http://172.105.158.32/geoserver/cite',
    // GEOSERVER: 'http://localhost:8080/heritageservices',
    // GEOSERVER: '/gs/geoserver/cite', //prod
    // GEOSERVER_PROXY: 'http://li929-88.members.linode.com:8080/geoserver/cite',
    OSM: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
};

export const coordinates = {
    BOUNDS: [[38.872454, -77.545538], [38.982806, -77.402297]],
    CENTER: [38.937324, -77.447791]
};

export const defaultReqParams = {
    outputFormat: 'application/json',
    maxFeatures: 350,
    request: 'GetFeature',
    service: 'WFS',
    version: '1.0.0'
};

export const landmarkColumns = [
    {
        field: 'label', headerName: 'Landmark', type: 'string', width: 230
    },
    {
        field: 'owner_1860', headerName: 'Owner in 1860', type: 'string', width: 130
    },
    {
        field: 'year', headerName: 'Built*', type: 'number', width: 55, valueFormatter: ({ value }) => parseFloat(value).toFixed(0)
    },
    {
        field: 'extant', headerName: 'Extant', type: 'string', width: 70
    }
];
export const mapConfig = {
    MINZOOM: 14,
    MAXZOOM: {
        1830: 14,
        1870: 16,
        1957: 17,
        1994: 17,
        now: 18
    },
    VISCOSITY: 0.9,
    ZOOMSNAP: 1,
    ZOOM: 14,
    keepBuffer: 10
};

export const markerIconSizes = {
    standardLandmark: [12, 12],
    dwelling1860: [5, 5],
    worship1860: [6, 10],
    postal1860: [10, 8],
    store: [10, 8],
    industrial: [10, 8],
    photo: [14, 12],
    link: [17, 17],
    nature: [14, 10]
};

export const colors = {
    WEATHERED: '#f0e5d7',
    PARCHMENT: '#f3e6d4',
    SCRIPT: '#dfc9aa',
    WHITE: '#ffffff'
};

export const markerIcons = {
    Worship: {
        iconUrl: './png/1860_worship.png',
        iconSize: markerIconSizes.worship1860
    },
    Church: {
        iconUrl: './png/1860_worship.png',
        iconSize: markerIconSizes.worship1860
    },
    Cemetery: {
        iconUrl: './png/dead-death-grave-512.png',
        iconSize: markerIconSizes.standardLandmark
    },
    Dwelling: {
        iconUrl: './png/1860_dwelling.png',
        iconSize: markerIconSizes.dwelling1860
    },
    House: {
        iconUrl: './png/1860_dwelling.png',
        iconSize: markerIconSizes.dwelling1860
    },
    School: {
        iconUrl: './png/school.png',
        iconSize: markerIconSizes.standardLandmark
    },
    Farm: {
        iconUrl: './png/1860_dwelling.png',
        iconSize: markerIconSizes.dwelling1860
    },
    Industrial: {
        iconUrl: './png/factory.png',
        iconSize: markerIconSizes.industrial
    },
    Airfield: {
        iconUrl: './png/1860_dwelling.png',
        iconSize: markerIconSizes.dwelling1860
    },
    Store: {
        iconUrl: './png/store.png',
        iconSize: markerIconSizes.store
    },
    Auto: {
        iconUrl: './png/store.png',
        iconSize: markerIconSizes.store
    },
    Fuel: {
        iconUrl: './png/store.png',
        iconSize: markerIconSizes.store
    },
    Fire: {
        iconUrl: './png/store.png',
        iconSize: markerIconSizes.store
    },
    Police: {
        iconUrl: './png/1860_dwelling.png',
        iconSize: markerIconSizes.dwelling1860
    },
    Postal: {
        iconUrl: './png/post-office-black.png',
        iconSize: markerIconSizes.postal1860
    },
    Restaurant: {
        iconUrl: './png/1860_dwelling.png',
        iconSize: markerIconSizes.dwelling1860
    },
    photo_modern: {
        iconUrl: './png/camera_outlined_modern.png',
        iconSize: markerIconSizes.photo,
        className: 'photo-icon'
    },
    photo_old: {
        iconUrl: './png/camera_outlined_old.png',
        iconSize: markerIconSizes.photo,
        className: 'photo-icon'
    },
    Nature: {
        iconUrl: './png/nature.png',
        iconSize: markerIconSizes.nature
    }
};

export const linkMarkerIcons = {
    Dwelling: {
        iconUrl: './png/1860_closeup_link.png',
        iconSize: markerIconSizes.link
    },
    House: {
        iconUrl: './png/1860_closeup_link.png',
        iconSize: markerIconSizes.link
    },
    Farm: {
        iconUrl: './png/1860_closeup_link.png',
        iconSize: markerIconSizes.link
    },
    Nature: {
        iconUrl: './png/1860_closeup_link.png',
        iconSize: markerIconSizes.link
    },
    Store: {
        iconUrl: './png/1860_closeup_link.png',
        iconSize: markerIconSizes.link
    },
    Airfield: {
        iconUrl: './png/1860_closeup_link.png',
        iconSize: markerIconSizes.link
    }
};

export const availableYears = [
    {
        id: '1830',
        label: 'c. 1820 🚧',
        wms: '1820',
        value: 27,
        bgColor: colors.WEATHERED
    },
    {
        id: '1870',
        label: 'c. 1870',
        wms: '1860',
        value: 42,
        bgColor: colors.PARCHMENT
    },
    {
        id: '1957',
        label: '1957',
        wms: '1957',
        value: 65,
        bgColor: colors.WHITE
    },
    // {
    //     id: '1994',
    //     label: '1994',
    //     wms: 'topo_1994',
    //     value: 66,
    //     bgColor: colors.WHITE
    // },
    {
        id: 'now',
        label: 'Today',
        value: 100,
        bgColor: colors.WHITE
    }
];

export const allowedContextMenuClasses = [
    'map-container',
    'leaflet-marker-icon',
    'photo-marker'
];

export const communities = [
    {
        id: 0,
        name: 'Arcola',
        center: [38.94662669557216, -77.52347946166994],
        zoom: 15
    },
    {
        id: 1,
        name: 'Chantilly',
        center: [38.89443982934927, -77.44275569915773],
        zoom: 15
    },
    {
        id: 2,
        name: 'Conklin',
        center: [38.89524145333019, -77.51451015472414],
        zoom: 16,
        year: 1957
    },
    {
        id: 3,
        name: 'Floris',
        altName: 'Frying Pan',
        center: [38.94080223428745, -77.41700649261476],
        zoom: 16
    },
    {
        id: 4,
        name: 'Pleasant Valley',
        center: [38.91654797334943, -77.49022006988527],
        zoom: 15
    },
    {
        id: 5,
        name: 'Willard',
        center: [38.93976746618343, -77.455952167511],
        zoom: 16,
        year: 1957
    }
];

export const articles = [
    {
        id: 9,
        name: 'Aristocracy'
    }
];

export const tutorialVideos = [
    {
        path: 'change_time_period_annotated_c.mp4',
        title: 'Change time period'
    },
    {
        path: 'landmark_press_annotated_c.mp4',
        title: 'View landmarks'
    },
    {
        path: 'view_photograph_annotated_c.mp4',
        title: 'View photographs'
    },
    {
        path: 'select_community_annotated_c.mp4',
        title: 'View communities'
    },
    {
        path: 'special_layer_annotated_c.mp4',
        title: 'View special layers'
    },
    {
        path: 'chancery_case_annotated_c.mp4',
        title: 'View chancery cases'
    }
];
