import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile, isIPad13 } from 'react-device-detect';
import {
    Slider
} from '@mui/material';

import { setYear, selectYear } from './yearSlice';
import './year.css';
import {
    availableYears
} from '../../app/constants';

export default function YearPicker() {
    const [sliderValue, setSliderValue] = React.useState(27);
    const dispatch = useDispatch();
    const currentYear = useSelector(selectYear);

    useEffect(() => {
        if (sliderValue !== currentYear) {
            const foundYear = availableYears.find((availableYear) => parseInt(availableYear.id) === currentYear);
            if (foundYear) {
                setSliderValue(foundYear.value);
            }
        }
    }, [currentYear]);

    function valueLabelFormat(value) {
        return availableYears.find((mark) => mark.value === value).label;
    }
    function evaluatedMark(selectedMark, value) {
        const determinedYear = availableYears.find((availableYear) => availableYear.value === value);
        setSliderValue(value);
        dispatch(setYear(parseInt(determinedYear.id) || determinedYear.id));
    }

    return (
        <div className={(!isMobile || isIPad13) ? 'timeline-h' : 'timeline-h-mobile'}>
            <div style={{ width: '100%' }}>
                <div
                    className="war-period"
                    style={{
                        backgroundColor: 'red',
                        width: '8%',
                        opacity: 0.5
                    }}
                >
                    <p>⚔</p>
                </div>
                <div
                    className="war-period"
                    style={{
                        backgroundColor: 'red',
                        width: '3%',
                        left: '23%',
                        opacity: 0.5
                    }}
                >
                    <p>⚔</p>
                </div>
                <div
                    className="war-period"
                    style={{
                        backgroundColor: 'red',
                        width: '4%',
                        left: '37%',
                        opacity: 0.5
                    }}
                >
                    <p>⚔</p>
                </div>
            </div>
            <Slider
                valueLabelFormat={valueLabelFormat}
                aria-labelledby="discrete-slider-restrict"
                step={null}
                value={sliderValue}
                valueLabelDisplay="auto"
                marks={availableYears}
                onChange={evaluatedMark}
                track={false}
            />
            <div style={{ width: '100%' }}>
                <div
                    className="time-period"
                    style={{
                        backgroundColor: '#E16A54',
                        width: '38%',
                        marginTop: -37,
                        opacity: 1.0
                    }}
                >
                    <p>SLAVERY</p>
                </div>
                <div
                    className="time-period"
                    style={{
                        backgroundColor: '#F39E60',
                        width: '28%',
                        marginTop: -37,
                        left: '41%',
                        opacity: 1.0
                    }}
                >
                    <p>SEGREGATION</p>
                </div>
            </div>
        </div>
    );
}
